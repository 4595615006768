:root {
  --fontFamilyHel: Helvetica Neue !important;
  --fontFamily: Inter !important;
  --fontFamilyKarla: Karla !important;
 }
 

.access-headline {
  color: #f0f0f5 !important;
  font-family: var(--fontFamily) !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important; /* 90.909% */
  letter-spacing: 1.833px !important;
  text-transform: uppercase !important;
}

.access-grid-topDiv {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.access-grid-child1 {
  display: flex !important;
  gap: 20px !important;
}

.access-icons {
  width: 60px !important;
  height: 60px !important;
  flex-shrink: 0 !important;
  border-radius: 60px !important;
  border: 3px solid #212121 !important;
}

.access-names {
  color: #f0f0f5 !important;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.access-userType {
  color: #8f92a1 !important;
  font-family: var(--fontFamily) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.access-cancel {
  width: 24px !important;
  height: 24px !important;
  flex-shrink: 0 !important;
}

.contentEnd {
  display: flex !important;
  justify-content: flex-end !important;
  cursor: pointer !important;
}

.details-access-number {
  color: rgb(143, 146, 161) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  font-family: var(--fontFamily) !important;
  cursor: pointer !important;
}


.accessArtWork{
  width: 100px !important;
  height: 60px !important;
  border-radius: 11px !important;
}