:root {
  --InterfontFamily: Inter;
}
.mobile-view {
  display: none;
}
.audio-player {
  width: 100%;
  background-color: #090909;
  height: 100px;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}
input[type='image']:disabled {
  opacity: 0.5;
}
.song-details {
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.song-title-custom{
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width: 100px; */
  /* width: 73%; */
  width: 80% !important;
}

.music-icon-size {
  /* width: 45px !important;
  height: 45px !important; */
  width: 38px !important;
  height: 37px !important;
  cursor: pointer;
}

.song-details img {
  /* height: 53px;
  width: 51px; */
  height: 63px;
  width: 61px;
}
.song-details .label {
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
}
.label h3 {
  margin-top: auto;
  margin-bottom: auto;
  font-family: Poppins;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  text-transform: lowercase;
  /* display: inline-block; */
}

.label h3:first-letter {
  text-transform: uppercase;
}
.label p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #99999f;
  text-transform: lowercase;
  /* display: inline-block; */
}

.label p:first-letter {
  text-transform: uppercase;
}

.song-control {
  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.play-control {
  display: flex;
  gap: 50px;

  justify-content: center;
  align-items: center;
}

.progress-bar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}
.seek-bar {
  width: 100%;
}
.slider-bar{
  width: 100%;
}
.progress-bar p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.song-volume {
  display: flex;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.song-volume #download {
  border: none;
  outline: none;
  padding: 8px 23px 9px 22px;
  border-radius: 19px;

  box-shadow: 0 0 7px 0 #000, inset 1px -1px 2px 0 rgba(69, 69, 69, 0.5);

  background-color: #f00;

  font-weight: bold;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: normal;

  color: #fff;
  cursor: pointer;
}

.volune {
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: center;
  margin-right: 20%;
}
#music-icon {
  /* height: 25px;
  width: 25px; */
   height: 38px;
  width: 40px;
  cursor: pointer;
}

#music-icon-volumn{
  height: 25px;
  width: 25px;
}
@media screen and (max-width: 1700px) {
  .volune {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    margin-right: 0%;
  }
}
@media screen and (max-width: 620px) {
  .mobile-view {
    display: flex;
    text-align: center;
    padding: 5px;
    justify-content: center;
    height: 40px;
    background-color: #090909;
  }
  .audio-player {
    height: 60px;
    grid-template-columns: 0.5fr 2fr 1.7fr;
  }
  .song-details {
    padding-left: 5px;
    padding-right: 5px;
  }
  .song-details img {
    height: 40px;
    width: 40px;
  }
  /* .song-details .label {
    display: none;
  } */
  .play-control {
    gap: 38px;
  }
  #music-icon {
    /* height: 12px;
    width: 12px; */
    height: 24px;
    width: 26px;
    cursor: pointer;
  }
  #music-icon-volumn {
    /* height: 12px;
    width: 12px; */
    cursor: pointer;
  }
  
  .song-volume {
    gap: 5px;
    margin-left: 2px;
    margin-right: 5px;
  }
  .progress-bar p {
    font-size: 10px;
  }
  .song-volume #download {
    padding: 5px;
    font-size: 8px;
    font-weight: 600;
  }
}
@media screen and(min-width:620px) and (max-width: 1000px) {
  .song-details img {
    height: 50px;
    width: 50px;
  }
  .song-details {
    padding-left: 8px;
  }
  .label h3 {
    font-size: 10px;
  }
  .label p {
    font-size: 10px;
  }
  .song-volume {
    margin-left: 5px;
    margin-right: 5px;
    gap: 5px;
  }
  .song-volume #download {
    padding: 7px;
    font-size: 10px;
    font-weight: 500;
  }
  #music-icon {
    /* height: 15px;
    width: 15px; */
    height: 25px;
    width: 28px;
    cursor: pointer;
  }

  #music-icon-volumn {
    height: 15px;
    width: 15px;
    cursor: pointer;
  }

}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .song-volume {
    margin-left: 5px;
    margin-right: 10px;
    /* gap: 5px; */
    gap: 15px;
  }
  #music-icon {
    /* height: 18px;
    width: 18px; */
    height: 39px;
    width: 40px;
    cursor: pointer;
  }
  #music-icon-volumn {
      height: 18px;
    width: 18px;
  }
  .song-volume #download {
    padding: 7px;
    font-size: 15px;
    font-weight: 600;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .music-play-icon {
    /* height: 38px !important;
    width: 38px !important; */
    height: 50px !important;
    width: 48px !important;
    cursor: pointer;
  }
  .music-play-icon-1 {
    font-size: 50px !important;
  }
}
@media screen and (min-width: 1600px) {
  .music-play-icon {
    height: 40px !important;
    width: 40px !important;
    cursor: pointer;
  }
  .music-play-icon-1 {
    font-size: 50px !important;
  }
}

.admin-settings-pwd-update-modal .ant-modal-header,
.admin-settings-pwd-update-modal .ant-modal-close {
  display: none;
}

.admin-settings-pwd-update-modal-head {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  font-weight: 600;
}

.admin-settings-pwd-update-modal-txt {
  font-size: 14px;
  text-align: center;
  color: #b9b9b9;
}

.admin-settings-pwd-update-modal-footer-sec-save-btn {
  background-color: #96e14f !important;
  padding: 8px 26px !important;
  color: #000000 !important;
  border-radius: 12px !important;
  border: none !important;
  height: 36px !important;
  width: 136px;
}

.admin-settings-pwd-update-modal-footer-sec-cancel-btn {
  background-color: #19191a !important;
  padding: 8px 26px !important;
  color: #ffffff !important;
  border-radius: 12px !important;
  border: none !important;
  height: 36px !important;
  width: 136px;
}

.admin-settings-pwd-update-modal .ant-modal-body {
  padding-top: 36px;
  /* height: 446px !important; */
}

.admin-settings-pwd-update-modal .ant-modal-footer {
  padding-bottom: 36px;
}

.admin-settings-pwd-update-modal .ant-modal-content {
  border-radius: 15px !important;
}
.selectPlaylist
  .ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background: transparent !important;
  border-bottom: 0px !important;
}


.selectPlaylist
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0px !important;
}
.selectPlaylist .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}
.ant-modal-content .ant-select {
  padding: 0px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.ant-modal-content .ant-input {
  padding: 0px 0px 5px 0px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.ant-modal-content .ant-input:focus {
  box-shadow: none !important;
}
.ant-modal-content .ant-input::placeholder {
  padding: 0px !important;
  font-size: 16px !important;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: transparent !important;
}


.ant-slider > ant-slider-rail{
  height: 90% !important;
  background-color: #212121 !important;
  border-radius: 9px !important;

}



.ant-slider-handle{
  display: none;
}

.slider-bar > .ant-slider-rail {
  height: 8px;
}


.video-cust-button {
  border-bottom-right-radius: 20.5px !important;
  border-top-right-radius: 20.5px !important;
  color: #ffffff !important;
  /* background-color: #171717 !important; */
  background-color: #424b5a !important;
  width: 91px !important;
  cursor: pointer !important;
  height: 35px !important;
  margin-left: -14px !important;
  /* height: 33px !important; */
}

.video-cust-button:disabled {
  background-color: red !important;
  cursor: not-allowed !important;
}
.audio-cust-button {
  border-radius: 20px !important;
  /* border-top-left-radius: 20.5px !important; */
  color: #ffffff !important;
  background-color: #424b5a !important;
  width: 91px !important;
  z-index: 2 !important;
  height: 35px !important;
  /* margin-left: 14px !important; */
}

.audio-cust-button:disabled {
  background-color: red !important;
  cursor: not-allowed !important;
}

.cust-btn{
  height: 50px;
    font-size: 16px !important;
    border-radius: 30px !important;
    max-width: 70%;
    text-align: center;
    border: none!important;
    color: #fff!important;
}

.ant-slider-rail {

  background-color: #1e1d1c !important;
}

.cursor-not-allowed{
  cursor: not-allowed !important
}

@media (max-width: 620px) {
  .mobile-view-audio-title {
    font-size: 12px !important;
}
}

@media screen and (max-width: 700px) {
  .play-control {
    display: flex;
    grid-gap: 50px;
    gap: 30px !important;
    justify-content: center;
    align-items: center;
}
}
.ad-placement-text{
  font-family: var(--InterfontFamily) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #FFCB37 !important;

}
.align-oneLine{
  display: flex;
  align-items: center;
}