:root {
  --fontFamily: Inter !important;
  --fontFamilykarla: karla !important;
}
.openUpwindow {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.followerBackground {
  background-color: black !important;
  width: 30%;
  height: 80%;
  color: white;
}
.follow-fullname{
  font-family: var(--fontFamilykarla) !important;
  text-transform: capitalize;
  cursor: pointer;
}
.followers-container-head {
  color: rgb(255, 255, 255) !important;
  padding: 7px 0px;
  /* font-weight: 400; */
  text-align: center;
  font-weight: 600;
    font-family: var(--fontFamily) !important;
    text-transform: capitalize;
}

.follower-contain {
  background-color: black;
  height: 89%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px 20px;
}

.follower-user {
  margin: 15px 5px;
}

.follower-Detail {
  display: flex;
}

.gerycolor {
  color: darkgray;
}

.follower-info h3 {
  color: white;
  line-height: 1.2;
  /* display: inline-block; */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.follower-info{
  width: 100% !important;
  padding: 0px 10px !important;

}

.follower-button {
  padding: 5px 0px;
}

.follower-avatar{
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 50% !important;
   border: 1px solid rgb(33, 33, 33) !important
}

@media (max-width: 1180px) {
  .followerBackground {
    width: 35%;
  }
}

@media (max-width: 1050px) {
  .followerBackground {
    width: 40%;
  }
}

@media (max-width: 950px) {
  .followerBackground {
    width: 45%;
  }
}

@media (max-width: 820px) {
  .followerBackground {
    width: 50%;
  }
}

@media (max-width: 820px) {
  .followerBackground {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .followerBackground {
    width: 70%;
  }
}

@media (max-width: 520px) {
  .followerBackground {
    width: 80%;
  }
}

@media (max-width: 450px) {
  .followerBackground {
    width: 90%;
  }
}

/* padding: 0px 14px; */



@media (max-width: 300px) {
  .follower-contain {
    padding: 0px 10px;
  }
}
@media (max-width: 280px) {
  .follower-contain {
    padding: 0px;
  }
}
