:root {
  --fontFamily: Inter !important;
}

.analytics-loader {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 80vh !important;
}

.default-profile-icon-analytics {
  display: initial !important;
  width: 65% !important;
  height: 55% !important;
  object-fit: cover !important;
  position: absolute;
  top: 20%;
  left: 19%;
}

.analytics-avatar-listener {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 60px !important;
}

.default-profile-icon-header-analytics {
  border-radius: 60px;
  height: 40px;
  width: 40px;
  border: solid 2px #212121;
  position: relative;
  min-width: 40px !important;

}

.analytics-nxzloader-width {
  width: 5% !important;
}

.topstatscontainer {
  margin-top: 10px;
  height: 54px;
  padding: 7px 64.9px 7px 26px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #141414 !important;
  color: white;
}

.topAnaliticbox {
  overflow-y: auto;
  scrollbar-color: red !important;
  margin-top: 20px;
  padding: 0px 20px 0px 16px;
  margin-bottom: 10px;
}

.topAnaliticbox::-webkit-scrollbar-thumb {
  background-color: red !important;
}

.topAnaliticbox::-webkit-scrollbar-track {
  background: #585858 !important;
  border-radius: 10px !important;
}

.topAnaliticbox::-webkit-scrollbar {
  width: 10px;
  height: 0px !important;
}

.analytics_topheader_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 0px 16px;
}

.analytics_topheader_element {
  border-radius: 20px;
  background-color: #212121;
  display: flex;
  justify-content: space-evenly;
  padding: 3px;
}

.topstats_container_element {
  display: flex;
  gap: 8%;
  align-items: center;
  width: 10%;
}

.allplays_stats {
  display: flex;
  gap: 8%;
  justify-content: space-around !important;
}

.analytics_slider {
  width: 381px !important;
  height: 12px !important;
  padding: 3px 8px !important;
  border-radius: 10px !important;
}

.Top-141 {
  font-size: 22px !important;
  font-weight: 700 !important;
  font-family: var(--fontFamily) !important;
  text-align: left !important;
  color: #ffff !important;
}

.stats_tab {
  color: #ffff !important;
  border-radius: 35px !important;
  width: 137px;
  text-transform: capitalize !important;
  font-family: var(--fontFamily) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  text-align: center !important;
}

.california_text {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: 1px !important;
  color: #ffff !important;
  white-space: nowrap;
}

.analytics_icon_image {
  height: 24px;
  width: 24px;
}

.analytics_loop_image {
  height: 21px;
  width: 24px;
}

.allplays_stats_element {
  display: flex;
  gap: 10%;
  align-items: center;
}

.allplays_city_element {
  display: flex;
  align-items: center;
}

.analytics_listing_head {
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-align: left !important;
  color: #f0f0f5 !important;
  text-transform: capitalize !important;
}

.analytics_twentyhr {
  display: flex !important;
  justify-content: space-around !important;
}


.allplays_comment_element {
  display: flex;
  align-items: center;
}

.analytics_topheader_buttongroup {
  display: flex;
  justify-content: space-evenly;
  border-radius: 20px;
  background-color: #212121;
  padding: 6px;
}

.analytics_slider .MuiSlider-track {
  height: 10px !important;
  border-radius: 5px !important;
}

.analytics_slider .MuiSlider-thumb {
  width: 0px !important;
  display: none !important;
}

.analytics_slider .MuiSlider-rail {
  height: 10px !important;
  border-radius: 10px !important;
  background-color: #070707 !important;
}

.nolistenertext {
  display: flex;
  align-items: center;
  font-size: 16;
  font-weight: 600;
  color: #fff;
  padding: 5;
  justify-content: center
}

/* responsive */

@media screen and (max-width: 400px) {
  .topstats_container_element {
    width: 38% !important;
  }

  .topAnaliticbox {
    padding: 0px 10px 0px 16px;
  }
}

@media screen and (max-width: 432px) {
  .stats_tab {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .analytics_topheader_element {
    justify-content: space-between !important;
  }

  .analytics_topheader_buttongroup {
    justify-content: space-between !important;
  }

  .analytics_twentyhr {
    display: flex !important;
    justify-content: space-between !important;
    padding-right: 20px !important;
  }

  .topAnaliticbox {
    padding: 0px 10px 0px 16px;
  }

  .analytics_topheader_container {
    flex-direction: column-reverse;
    align-items: normal;
    gap: 10px;
  }

  .topstats_container_element {
    width: 28%;
  }

  .topstatscontainer {
    flex-direction: column;
    height: auto;
    align-items: normal;
    padding: 7px 12.9px 7px 26px !important;
    gap: 6px !important;
  }

  .allplays_stats {
    display: flex;
    gap: 8%;
    justify-content: space-between;
  }

  .analytics_slider {
    width: auto;
    width: 100% !important;
  }
}

@media (min-width: 601px) and (max-width: 950px) {
  .analytics_slider {
    width: 160px;
  }

  .topAnaliticbox {
    padding: 0px 10px 0px 16px;
  }
}

@media screen and (max-width: 1200px) {
  .analytics_listing_head {
    font-size: 14px !important;
    font-weight: 600 !important;

  }

  .topstatscontainer {
    padding: 7px 26px 7px 26px !important;
  }
}