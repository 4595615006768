:root {
    --fontFamily: Karla !important;
}

.prev-button {
    top: 36%;
}

.revenue_topheader {
    height: 122px;
    flex-grow: 0;
    padding: 15px 63.7px 15px 20px;
    object-fit: contain;
    border-radius: 22px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    border: solid 3px #212121;
    background-color: var(--color-01-100);
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.mobile_revenue_topheader {
    height: 122px;
    width: 220px;
    flex-grow: 0;
    padding: 15px 63.7px 15px 20px;
    object-fit: contain;
    border-radius: 22px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    border: solid 3px #212121;
    background-color: var(--color-01-100);
}

.revenue_content_leftside {
    padding: 15px 63.7px 15px 20px;
}

.arrow_up_lable {
    display: flex;
    color: blue;
    background-color: #2e2d2d;
    width: 55%;
    padding: 5px;
    border-radius: 20px;
    align-items: center;
    margin-top: 10px
}

.arrow_up_text {
    font-size: 12px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.67 !important;
    letter-spacing: 1px !important;
    text-align: left !important;
    color: #3959f9 !important
}

.revenue_sales_data {
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.38 !important;
    letter-spacing: normal !important;
    color: #ffffff !important;
    font-family: var(--fontFamily) !important;


}

.revenue_month {
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: -1px !important;
    color: #ffffff !important;
    font-family: var(--fontFamily) !important;
}

.revenue_arrow {
    width: 23px;
    height: 23.7px;
}

.arrow_up {
    width: 18px;
    height: 18px;
    margin-right: 5px
}

.revenue_topheader_container {
    display: flex;
    gap: 4%;
    margin-top: 30px;
    width: 94%;
    margin-left: 1.5%;
    flex-direction: row;
    margin-bottom: 10px;

}

.revenue_topheader_containers {
    display: flex;
    flex-direction: row;
    width: 94%;
    margin-left: 1.5%;
    justify-content: space-between;
    gap: 8px;
}

.revenue_leftside_wrapper {
    width: 57.6%;
    display: flex;
    flex-Direction: column;
    gap: 6px !important;
}

.revenue_leftside_element_box {
    height: 42px;
    padding: 9px 20px 9px 54px;
    object-fit: contain;
    border-radius: 8px;
    background-color: #212121;
    display: flex;
    justify-content: space-between
}

.revenue_rightside_content {
    width: 38.2%;
    height: 570px;
    padding: 3px 2.9px 3px 2.9px;
    border-radius: 22px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    border: solid 3px #212121;
    background-color: #000000;
}


.revenue_topheader_text {
    width: 143px;
    height: 30px;
    flex-grow: 0;
    font-size: 22px;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 30px !important;
    letter-spacing: -1px !important;
    text-align: left;
    color: white;
    font-family: var(--fontFamily) !important;
}

.revenue_topheader_title {
    height: 20px;
    flex-grow: 0;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43 !important;
    letter-spacing: normal !important;
    text-align: left;
    color: #8f92a1;
}

.revenue_amount_data {
    color: #8f92a1 !important
}

.plus_icon {
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -1.73px;
    text-align: left;
    color: #3959f9
}

.revenue_balance {
    width: 80%;
    /* height: 20px; */
    flex-grow: 0;
    display: flex;
    justify-content: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 19px;
    border: solid 1px #ffffff;
    align-items: center;
}

.revenue_amount {
    width: 229px;
    height: 52px;
    flex-grow: 0;
    margin: 4px 0 0;
    font-size: 32px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.revenue_lastquarter {
    width: 293.8px;
    height: 19px;
    flex-grow: 0;
    margin: 8px 0.2px 0 1px;
    object-fit: contain;
    border-radius: 5px;
    background-color: #212121;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Last-Quarter {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.revenue_right_box {
    display: flex;
    flex-direction: column;
    width: 98%;
    align-items: center;
    margin-top: 22%;
}

.next-button {
    top: 36%;
    right: 30%;
}

.orginal-content .slick-slide {
    width: 350px;
}

.orginal-content .ant-carousel .slick-list {
    padding: 0 20% 0 0 !important;
}



@media screen and (max-width:410px) {
    .prev-button {
        top: 43%;
    }

    .next-button {
        top: 43%;
        right: 7.5%;
    }
}

.revenue_balance_media {
    width: 80%;
}

@media screen and (max-width:3000px) {
    .mobile_revenue {
        display: none;
    }


}

@media screen and (max-width:1479px) {
    .mobile_revenue {
        display: block;
        padding-left: 20px;
        padding-right: 55px;
    }

    .revenue_topheader_containers {
        display: none;
    }

    .prevcara {
        left: 94% !important;
    }


}

@media (min-width:960px) and (max-width:1105px) {
    .revenue_topheader_container {
        display: flex;
        flex-direction: column;
        gap: 15px !important;

    }

    .revenue_leftside_wrapper {
        width: 100% !important;
    }

    .revenue_rightside_content {
        width: 100% !important;
    }

}

@media (min-width:500px) and (max-width:600px) {
    .mobile_revenue {
        padding-right: 35px !important;
    }
}

@media (min-width:901px) and (max-width:959px) {
    .mobile_revenue {
        padding-left: 19px !important;
        padding-right: 36px !important
    }

    .prevcara {
        left: 94% !important
    }
}

@media screen and (max-width:900px) {
    .mobile_revenue {
        padding-left: 15px;
        padding-right: 40px;
    }

    .next-button {

        right: 20%;
    }

    .prevcara {
        left: 92.5% !important
    }


    .revenue_topheader_containers {
        flex-wrap: wrap;
        place-content: center;
    }

    .revenue_topheader_container {
        display: flex;
        flex-direction: column;
        padding-left: 15px !important;
        margin-left: 1.5%;
        gap: 15px !important;

    }

    .revenue_chart {
        margin-top: 10%;
    }

    .revenue_leftside_element_box {
        height: 42px;
        margin: 0px 0px 0px 0px;
        padding: 9px 4px 9px 4px;
        -o-object-fit: contain;
        object-fit: contain;
        border-radius: 8px;
        background-color: #212121;
        display: flex;

    }

    .revenue_leftside_wrapper {
        width: 100%;
        display: flex;
        flex-Direction: column;
        grid-gap: 6px !important;
        gap: 6px !important;
    }

    .revenue_rightside_content {
        width: 100%;
        margin-top: 10px,
    }
}

@media screen and (max-width:784px) {

    .next-button {

        right: 15%;
    }
}

@media screen and (max-width:600px) {

    .next-button {

        right: 35%;
    }

    .revenue_topheader_container {
        display: flex;
        flex-direction: column;
        padding-left: 10px !important;
        margin-left: 1.5%;

    }

    .revenue_chart {
        margin-top: 10%;
    }

    .revenue_topheader_containers {
        flex-wrap: wrap;
        place-content: center;

    }

    .revenue_leftside_element_box {
        height: 42px;
        margin: 0px 0px 0px 0px;
        padding: 9px 4px 9px 4px;
        -o-object-fit: contain;
        object-fit: contain;
        border-radius: 8px;
        background-color: #212121;
        display: flex;

    }

    .revenue_leftside_wrapper {
        width: 100%;
        display: flex;
        flex-Direction: column;
        grid-gap: 6px !important;
        gap: 6px !important;
    }

    .revenue_rightside_content {
        width: 100%;
        margin-top: 10px;
        border: none !important;
    }

    .mobile_revenue {
        padding-left: 15px;
        padding-right: 18px;
    }
}

@media (min-width:320px) and (max-width:465px) {

    .prevcara {
        left: 89% !important
    }
}