.trackAdBudget {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    overflow: hidden auto;
    scrollbar-width: none; /* Firefox */
    position: relative;
    margin: -10px !important;
  padding: 20px !important;
  background: black !important;
}
.trackAdBudget::-webkit-scrollbar {
    /* display: none; */
    width: 0;
}

.scrollerWidth::-webkit-scrollbar {
  display: none;
  width: 0;
}

.default-profile-video {
  display: initial !important;
  height: 55% !important;
  object-fit: cover !important;
  position: absolute;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%,-50%);
}