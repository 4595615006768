.AVIMainContainerCP {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 16px;
}

@media screen and (max-width: 1239px) {
    .AVIMainContainerCP {
      width: 325px;
    }
}

.StatsResponsive{
    overflow-y: auto;
    height: calc(509px - 30%) !important;
}

.AVInfoContainerCP {
    border: 3px solid #212121;
    border-radius: 8px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AVInfoContainerCP.AudioCP {
    width: 226px;
    height: 226px;
}

.AVInfoContainerCP.VideoCP {
    width: 376px;
    height: 226px;
}

@media screen and (min-width: 1240px) and (max-width: 1280px) {
    .AVInfoContainerCP.VideoCP {
        width: 310px;
        height: 226px;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1350px) {
    .AVInfoContainerCP.VideoCP {
        width: 325px;
        height: 226px;
    }
}

@media screen and (min-width: 1351px) and (max-width: 1400px) {
    .AVInfoContainerCP.VideoCP {
        width: 350px;
        height: 226px;
    }
}

.AVInfoContainerCP .PlayBtnCP {
    height: 48px;
    width: 48px;
    border-radius: 100px;
    background: #00000088;
}
.AVInfoContainerCP .PlayBtnCP:hover{
    background: #00000088;
}

.AVInfoContainerCP .PlayBtnCP .MuiSvgIcon-root {
    color: white;
}

.AVIMainContainerCP .AVICMediaNameCP {
    color: white;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    margin-top: 24px;
}

.AVIMainContainerCP .AVICMediaCreatorNameCP {
    color: #717171;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    margin-top: 4px;
}

.AVIMainContainerCP .AVICButtonsContainerCP {
    width: 238px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AVICButtonsContainerCP .AVICBtnBoxCP {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.AVICBtnBoxCP .BtnCP {
    height: 48px;
    width: 48px;
    border: 2px solid #717171;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AVICBtnBoxCP .BtnTextCP {
    color: white;
    font-size: 12px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: normal;
    font-family: 'Inter', sans-serif;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    margin-top: 12px;
}

.StatsMainBox{
    width: 345px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 9px;
    padding: 0;
  }
  
  @media screen and (max-width: 600px) {
    .StatsMainBox{
        margin-left: 30px;
    }
  }
  @media screen and (min-width: 1240px) and (max-width: 1350px) {
      .StatsMainBox{
       width: 300px;
      }
}

.StatsMainBox .StatsTitleBox{
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 25px;
}

.StatsTitleBox .StatsTitle{
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-stretch: normal;
    line-height: normal;
    color: white;
    font-weight: 800;
    margin-left: 16px;
}

.StatsRow{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

@media screen and (min-width: 1240px) and (max-width:1300px) {
    .StatsRow {
     width: 89%;
    }
}

body .MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.StatsCardCP{
    width: 165px;
    height: 58px;
    flex-grow: 0;
    margin: 0 15px 0 0;
    padding: 10px 57px 10px 10px;
    border-radius: 7px;
    border: solid 2px #141414;
    background-color: #070707;
}

.StatsCardCP .StatsTitleCP{
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    font-stretch: normal;
    line-height: 1.5;
    color: rgb(57, 89, 249);
    font-weight: 800;
    white-space: nowrap;
}
.StatsCardCP .StatsValueCP{
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    font-stretch: normal;
    line-height: 1.5;
    color: #ffffff;
    font-weight: 800;
    white-space: nowrap;
}

@media screen and (max-width:599px) {

    .AVIMainContainerCP .AVICButtonsContainerCP {
        width: 90%;
    }

    .AVInfoContainerCP.AudioCP {
        width: 220px;
        height: 220px;
    }
    .StatsCardCP{
        width: 140px;
    }
    .StatsCardCP .StatsTitleCP{
        font-size: 14px;
    }
    .StatsCardCP .StatsValueCP{
        font-size: 18px;
    }
}

@media screen and (max-width:410px) {
    .AVIMainContainerCP .AVICButtonsContainerCP {
        width: 90%;
    }
    .StatsCardCP{
        width: 120px;
    }
    .StatsCardCP .StatsTitleCP{
        font-size: 12px;
    }
    .StatsCardCP .StatsValueCP{
        font-size: 16px;
    }
    .StatsTitleBox .StatsTitle{
        font-size: 18px;
    }
}


@media screen and (max-width:300px) {
    .AVIMainContainerCP .AVICButtonsContainerCP {
        width: 95%;
    }
}