:root {
  --fontFamily: Inter;
}

.download_banner_main {
  background-size: cover;
  height: 40% !important;
  display: flex;
  align-items: flex-end;
  padding: 0px 0px 50px 80px;
}

.banner_heading {
  font-family: var(--fontFamily) !important;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  text-transform: lowercase;
  display: inline-block;
}



.banner_heading:first-letter {
  text-transform: uppercase;
}

.track_name {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin: 8px;
  flex-grow: 0;
}

.banner_track {
  font-family: var(--fontFamily) !important;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}