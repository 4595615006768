.UserCardCommon {
    height: 68px;
    width: 348px;
    background-color: #000000;
    border-radius: 100px;
    position: relative;
    margin-top: 8px;
}

.UserCardCommon.Active {
    background-color: #212121;
}

.UserCardCommon.CursorPointer {
    cursor: pointer;
}

.UserCardCommon .ContentDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 68px;
    padding: 4px 16px;
}

.UserCardCommon .UserIcon {
    width: 46px;
    height: 46px;
    object-fit: contain;
    border-radius: 100px;
}

.UserCardCommon .UserInfoDiv {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 68px;
    width: 100%;
    margin-left: 16px;
}

.UserCardCommon .UserNameText {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

.UserCardCommon .UserTypeText {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

.UserCardCommon .UserEquityText {
    display: flex;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: white;
}

@media screen and (max-width: 599px) {

    .UserCardCommon {
        width: 328px;
    }
}