.SSPMainDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 348px;
}

.SSPMainDiv .SSPInputDiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 32px;
}

.SSPInputDiv .SelectSplitButton {
    width: 71px;
    height: 29px;
    border-radius: 50px;
    background-color: #212121;
}

.SSPInputDiv .SelectSplitButton:hover {
    background-color: #3959f9;
}

.SSPInputDiv .selectedSplit {
    background-color: #3959f9;
}

.SSPInputDiv .SelectSplitButton span {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: white;
    text-transform: none;
}

.SSPMainDiv .RemainingPercentageText {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.79;
    letter-spacing: normal;
    text-align: center;
    color: white;
}

.SSPMainDiv .MuiInputBase-input.MuiInput-input {
    color: white;
    font-family: "Inter", sans-serif;
    font-size: 42px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.38;
    letter-spacing: normal;
    text-align: center;
}

@media screen and (max-width: 599px) {

    .SSPMainDiv {
        width: 328px;
    }
}