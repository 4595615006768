
:root {
  --fontFamily: Inter !important;
}

.red_custom_btn_sms {
  border-color: #fe0000 !important;
  color: white !important;
  width: 301px;
  height: 60px;
  object-fit: contain;
  border-radius: 60px;
  background-color: #ff0010;
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
}

.grey_custom_btn {
  width: 301px;
  height: 60px;
  object-fit: contain;
  border-radius: 60px;
  border: solid 5px #121212;
  background-color: var(--color-1-100);
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: white !important;
  cursor: pointer;
}

.dialog-Paper-addtoqueue-popup {
  background-color: #020202 !important;
  border-radius: 40px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
  border: solid 5px #1f1f1f !important;
  width: 375px !important;
  height: 650px !important;
  align-items: center;
  justify-content: center;
}

.add-to-queue-frame {
  width: 325px;
  height: 275px;
  object-fit: contain;
  border-radius: 15px;
  border: solid 2px #212121;
  background-color: #070707;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.addtoqueueinsidebox{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column
}

.addtoqueuemainbox{
  justify-content: center;
  padding: 10px; 
  flex-direction: column ;
  align-items: center ;
  height: 100%
}

.addtotext{
  font-size: 22px;
  font-weight: 900; 
  color: gray
}

.queuetext{
  font-size: 32px;
  font-weight: 900;
  color: white;
  font-family: var(--fontFamily) !important
}

.addthistext{
  font-size: 16px;
  font-weight: bold;
  color: gray; 
  font-family: var(--fontFamily) !important
}
.bluetext{
  font-size: 16px;
  font-weight: bold;
  color: blue; 
  font-family: var(--fontFamily) !important
}

.add_to_playlist_box{
  display: flex;
  width: 90%;
  align-items: center;
  padding: 10px;
  margin: 15px; 
  border-radius: 60px;
  background-color: #141414;
  font-size: 18px;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily) !important;
  cursor:pointer
}

.add_to_queue_box{
  display: flex;
  width: 90%;
  cursor: pointer; 
  align-items: center;
  padding: 10px; 
  margin: 15px;
  border-radius: 60px;
  background-color: #141414;
  font-size: 18px;
  font-weight: bold;
  color: white;
  font-family: var(--fontFamily) !important
}

@media screen and (max-width: 420px) {
  .add-to-queue-frame {
    width: 265px;
    height: 255px;

  }
}


@media screen and (max-width: 400px) {
  .grey_custom_btn {
    width: 275px;
  }

  .red_custom_btn_sms {
    width: 275px;
  }
}