:root {
  --fontFmaily: Inter;
}

.myPlanLayout {
  /* max-width: 100vw; */
  overflow: hidden;
  background-color: rgb(31, 31, 31);
  height: 100vh;
}



.spinloadder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/**main**/

.myplancontainer {
  height: 93vh;
  /* overflow-x: hidden; */
  overflow-x: hidden;
  overflow-y: auto;
}

.myplancontainer::-webkit-scrollbar-thumb {
  background-color: red!important;
 }
 .myplancontainer::-webkit-scrollbar {
  width: 7px !important;
 }

.audio_planContainer{
  height: 73vh;
  /* overflow-x: hidden; */
  overflow-x: hidden;
  overflow-y: auto;
}

.PlanHeight {
  height: 750px;
}

.creative_Container {
  border-radius: 20px;
  background-color: #090909;
  height: 700px;
  display: flex;
  align-content: center;
}

.listener_contnt {
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0px 10px 0px 0px !important;
}

.listener_contnt::-webkit-scrollbar-thumb {
  background-color: red !important;
}

.listener_contnt::-webkit-scrollbar-track {
  background: #585858 !important;
  border-radius: 10px !important;
}

.topAnaliticbox::-webkit-scrollbar {
  width: 10px;
  height: 0px !important;
}

.without_listener_plan{
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0px 10px 0px 0px !important;
}
.creativePlan {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 100%;
}

.creativePlanPriceFont {
  color: #ffffff;
  /* font-size: 90px; */
  font-weight: 900;
  line-height: 1;
  margin-bottom: 5px;
  font-family: var(--fontFmaily);
  font-size: 60px;
}

.creativePlanButton {
  cursor: pointer;
  margin: 5px 0px;
  /* width: 70%;
    height: 18%; */
}
.creativeNoPlanBtn {
  margin: 5px 0px;
}

/* card information */
.payment_Card {
  border: 6px solid #212121;
  background-color: #000000 !important;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 15px;
}

.cardType {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardFont {
  /* font-weight: 800; */
  color: #ffffff;
  font-family: var(--fontFmaily);
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 98%;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.cardImage {
  margin-right: 5px;
  /* width: 12%; */
  width: 30px;
  /* height: 30px; */
}

.cardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12%;
  /* line-height: 0.8; */
}

.updateCardbtn {
  height: 55px;
  background-color: rgb(40 40 42);
  margin: 0px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.updateCardbtn button {
  color: #ffffff;
  background-color: rgb(40 40 42);
  border: none;
  font-family: var(--fontFmaily);
  font-size: 20px;
  font-weight: 900;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .creativePlan {
    height: 300px;
  }
  .creative_Container {
    height: 1000px;
    align-content: center;
  }
 /* new changesss */
 .myPlanContent {
  background-color: #000000;
 
}

/* new changesss */
.myplancontainer{
  height: 85vh !important;

}
.update-plans-popup{
display: flex !important;
justify-content: center !important;
align-items: center !important;
height: 100%;
overflow: hidden;
}
.plan_popup_container{
  height: 100% !important;
}
}
