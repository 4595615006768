
  
  .example::-webkit-scrollbar {
    display: none;
  }
  
  .hoverloc:hover {
    border-radius: 7px !important;
    padding: 5px;
    background-color: rgb(29, 28, 28);
    border: 1px solid rgb(102, 102, 102);
  }

.typehove{
    background-color: rgb(40, 40, 40);
}

  .typehove:hover{
    background-color: rgb(69, 36, 146);
  }
  .height_mobile_filter::-webkit-scrollbar-thumb {
    background-color: red !important;
    }
    
    .height_mobile_filter::-webkit-scrollbar-track {
    background: #585858 !important;
    border-radius: 10px !important
    }
    .height_mobile_filter::-webkit-scrollbar {
    width: 10px;
    height: 0px !important;
    }
    .height_mobile_filter{
      flex: 1; 
      padding: 5px;
      overflow-y: auto;
    }

  .button-upload-types{
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: white;
    border-radius: 20px;
    padding: 3px 20px;
    margin: 3px 3px;
    text-transform: capitalize;
    border-color: rgba(0, 3, 3, 0);
  }

  .hide_for_MVP_release{
    display: none !important;
  }

  @media screen and (max-width: 599px) {
    .height_mobile_filter{
      max-height: 340px;
    }
     
  }




  .search_city .css-b62m3t-container {
    border: none !important;
  }


  .search_city .css-13cymwt-control {
      background-color: rgb(40, 40, 40) !important;
      border: none !important;
      border-radius: 20px !important;
     /* border-bottom: 1px solid rgb(62 63 65) !important; */
     width: 100%;
     min-height: 40px !important;
     color: black !important

  }

  .search_city .css-1hb7zxy-IndicatorsContainer {
    /* display: none !important; */
  }

  .search_city .css-1u9des2-indicatorSeparator{
     display: none !important;
  }

  .search_city .css-15lsz6c-indicatorContainer{
    color: rgb(40, 40, 40) !important
  }
  
  .search_city .css-1xc3v61-indicatorContainer{
    color: rgb(40, 40, 40) !important
}

.search_city .css-1jqq78o-placeholder {
    display: flex !important;
  }

  .search_city .css-t3ipsp-control:hover{
    border-color: transparent !important
  }

  .search_city .css-t3ipsp-control {
     box-shadow: none !important;
     border-color: transparent !important;
     width: 100% !important;
    background-color:  rgb(40, 40, 40) !important;
    color: #8f92a1 !important;
    border-radius: 20px !important;
   /*  border: none !important;
     border-radius: 0px !important;
     padding: 0px !important;
    display: flex !important;
    margin: 0px !important; */
  }

  .search_city .css-1dimb5e-singleValue {
    color: #8f92a1 !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .search_city .css-166bipr-Input {
    color: gray !important;
    font-weight: bold;
   }
 
   .search_city .css-1cfo1cf {
     color: gray !important;
     font-weight: bold;
   }
  
   .search_city #react-select-2-input{
    color: #d3cccc !important;
   }

   .search_city .css-qbdosj-Input {
   color: gray !important;
  }

  .search_city .css-1nmdiq5-menu {
    display: none !important;
  }
