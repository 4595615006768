.container {
   /* height:"100vh"; */
   display: "flex";
   justify-content: "center";
   align-Items: "center";
   color: "white";
   flex-direction: column;
   /* border: 2px solid grey; */
}


.default-profile-header-profileMsgCard{
   height: 29px;
   width: 29px;
   border: solid 2px #212121;
   position: relative;
   border-radius: 29px;
}

.profileMsgCard-avatar{
   width: 100% !important;
   height: 100% !important;
   object-fit: cover !important;
   border-radius: 29px !important;
 }
 .default-profile-profileMsgCard{
   display: initial !important;
   width: 65% !important;
   height: 55% !important;
   object-fit: cover !important;
   position: absolute;
   top: 20%;
   left: 19%;
 
 }

.stream_history_img_default{
   display: initial !important;
   width: 80% !important;
   height: auto !important;
   object-fit: cover !important;
}

.stream_history_img{
   width: 100% !important;
height: 100% !important;
object-fit: cover !important;
border-radius: 9px !important;
}

.pushPlayerAd-avatar{
   width: 100% !important;
   height: 100% !important;
   object-fit: cover !important;
 }

.mediaDMCP-avatar{
   width: 100% !important;
   height: 100% !important;
   object-fit: cover !important;
   border-radius: 50% !important;
 }

 .repost_label_creative-avatar{
   width: 100% !important;
   height: 100% !important;
   object-fit: cover !important;
   border-radius: 8px !important;
 }

 .defaultProfile-repost_label_creative{
   display: initial !important;
   width: 70% !important;
   height: 70% !important;
   -o-object-fit: cover !important;
   object-fit: cover !important;
   position: absolute;
   margin-top: 12% !important;
   margin-left: 15% !important;
 
 }

 .pushPlayer-stats-artwork{
   width: 100%;
   height: 331px;
   border: 1px solid rgb(33, 33, 33);
   border-radius: 15px;
   object-fit: cover;
 }

 .push-result-art-work{
   border-radius: 10px !important;
  height: 100% !important;
  width: 100% !important;
  border: 1px solid rgb(33, 33, 33) !important;
 }

 .default-profile-mediaDMCP{
   display: initial !important;
   width: 65% !important;
   height: 55% !important;
   object-fit: cover !important;
   /* position: absolute; */
   margin-top: 20% !important;
   margin-left: 19% !important;
 }

 .default-profile-viewNewMessenger{
   display: initial !important;
   width: 70% !important;
   height: 55% !important;
   object-fit: cover !important; 
 }
 

.btn {
   border-radius: 100px;
   margin: 5px;
   background: #3959F9;
   padding: 10px 95px;
   height: 50px;
   color: white;
   font-weight: bold;
}


.flex {
   display: flex;
   /* justify-content: center; */
   align-items: center;
}


.heading {
   color: white;
   font-weight: bold;
   font-size: larger;
   letter-spacing: normal;
   font-stretch: normal;


}


.caption {
   text-align: center;
   color: white;
   font-size: medium;
}


/* chatintegration */


.chat {
   flex-grow: 8;
}


.containerChat {
   display: flex;
   flex-direction: column;
   /* padding-inline: 20px; */
   /* height: 100% !important; */
   position: relative !important;
   /* height: calc(100vh - 15%) !important; */
   height: calc(100vh - 90px) !important;

}

.audio-containerChat {
   display: flex;
   flex-direction: column;
   /* padding-inline: 20px; */
   position: relative !important;
   height: calc(100vh - 185px) !important;

}

.trackMsgCard-isTrack{
   height: 246px;
    width: 246px;
    border-radius: 3px;
    margin: 8px 0px;
    object-fit: cover;
}

.trackMsgCard-noTrack{
   height: 135px;
    width: 246px !important;
    border-radius: 3px;
    padding: 10px 0px;
    object-fit: cover;
}


.message {
   padding: 10px;
   background-color: #3959F9;
   width: 95%;
   overflow: hidden;
   border-radius: 20px;
   height: auto;
   line-break: anywhere;


}


.default-message {
   padding: 10px;
   background-color: #171717;
   /* width: 95%; */
   width: 275px;
   overflow: hidden;
   border-radius: 20px;
   height: auto;
   line-break: anywhere;
}




.chatLogo {
   width: 42px;
   height: 42px;
   border-radius: 50%;
   border: 2px solid #212121;
   align-items: center;


}


.chatsearchBar {
   flex: 1;
   width: 100%;
   /* display: flex; */
   /* justify-content: center; */
   align-items: center;
   position: absolute !important;
   bottom: 0 !important;
padding: 0px 8px !important;

}


.chatgridHead {
   display: flex;
   flex-direction: column;
   overflow: hidden scroll;
   overflow-y: auto !important;
   /* max-height: calc(100vh - 30%) !important; */
   max-height: calc(100vh - 170px) !important;
   width: 100% !important;
   padding-inline:2px !important;
}


.audio-chatgridHead {
   display: flex;
   flex-direction: column;
   overflow: hidden scroll;
   overflow-y: auto !important;
   /* max-height: calc(100vh - 30%) !important; */
   max-height: calc(100vh - 255px) !important;
   width: 100% !important;
}

.chatgridHead::-webkit-scrollbar {
   display: none;
}

.timer {
   border-radius: 30px;
   background-color: black;
   height: 20px;
   display: flex;
   justify-content: center;
   padding-inline: 15px;
   padding-block: 2px;
}


p {
   margin-bottom: 0px
}




/* card-block */


.cards {
   margin-inline: 10px;
   margin-block: 10px;
}


.container-card {
   width: 265px;
   height: 265px;
   background-color: #141414;
   margin-inline: 15px;
   margin-block: 15px;
}


.header {
   display: flex;
   align-items: center;
   padding: 5px 10px;


}


.card {
   display: flex;
   flex-wrap: wrap;
   flex: 8;
   justify-content: center;


}


.card-img {
   width: 60px;
   height: 60px;
}


.viewProfile {
   flex: 2;
   display: flex;
   justify-content: center;
}


.viewProfileBtn {
   height: 35px;
   border-radius: 30px;
   background-color: black;
   width: 225px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-block: 5px;
}


.embededLogo {
   display: flex;
   align-items: center;
   width: 70px;
   height: 285px;
   margin-right: -30px;
   z-index: 1;
   position: static;
   border-color: #141414;


}

::-webkit-scrollbar {
   width: 10px;
   height: 10px;
}

::-webkit-scrollbar-thumb {
   background: rgba(90, 90, 90);
}

::-webkit-scrollbar-track {
   background: rgba(0, 0, 0, 0.2);
}


.margin-vertical-card {
   margin: 15px 10px;
   border-radius: 10px;
}


/* delete-conversation */


.popupContainer {
   height: 280px;
   width: 280px;
}

@media screen and (max-width: 1040px) {
   

   .chatgridHead {
      width: 100% !important;
   }

  

   .chatInputBox {
      width: 100% !important;
   }
}

@media screen and (max-width: 1040px) and (max-width: 1239px) {
   .particularUserWeb {
      width: 290px;
    }
}

@media screen and (max-width: 1040px) and (min-width: 600px) {
   .chatsearchBar {
      width: 100% !important;
   }
   .containerChat {
      width: 100% !important;
      padding-inline: unset !important;
   }
   
   .particularUserWeb{
width: 100% !important;
   }
}

@media screen and (max-width: 599px){
   .chatsearchBar {
      width: auto !important;
   }
   .containerChat {
      padding-inline: unset !important;
      width: 100% !important;
   }
  
}

@media screen and (max-width: 1240px) and (min-width: 1040px) {
   .containerChat {
      width: 70% !important;
   }

   .chatgridHead {
      width: 100% !important;
   }

   .chatsearchBar {
      width: 100% !important;
   }

   .chatInputBox {
      width: 100% !important;
   }

}


@media screen and (max-width: 345px) {
   .chat-type {
      padding: 0px 3px !important;
   }

}