.create_audio_form .ant-input {
  background-color: #020202 !important;
  border-color: #020202 !important;
  border-bottom: 4px solid #242528 !important;
  color: white !important;
  padding: 4px 0px !important;
}

.create_audio_form .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.create_audio_form .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.create_audio_form .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.create_audio_form .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-bottom: 0px !important;
}

.create_audio_form .ant-form-item-explain-error {
  text-align: start !important;
  margin-top: 0px !important;
}

.create_audio_form .ant-form-item-explain,
.ant-form-item-extra {
  color: white !important;
  text-align: end !important;
  margin-top: 0px !important;
  cursor: pointer !important;
  align-self: start !important;
}

.create_audio_form .ant-input-affix-wrapper {
  background-color: #17181c !important;
  border-color: #17181c !important;
  border-bottom: 4px solid #242528 !important;
  padding: 0px !important;
}

.create_audio_form .ant-input-affix-wrapper>input.ant-input {
  border-bottom: none !important;
}

.create_audio_form .ant-form-item-label>label {
  color: #8f92a1 !important;
}

.dialog-Paper-create-audio {
  background-color: #020202 !important;
  border-radius: 40px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
  border: solid 5px #1f1f1f !important;
  width: 450px !important;
  height: 650px !important;
}

.dialog-Paper-create-audio::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

.create_audio_form .red_custom_btn_create {
  background-color: #fe0000 !important;
  border-color: #fe0000 !important;
  color: white !important;
  width: 232px;
  height: 58px;
  border-radius: 60px;
  font-size: 18px;
}

.grey_custom_btn {
  background-color: #1f1f1f !important;
  border-color: #1f1f1f !important;
  color: white !important;
  border-radius: 30px;
  font-size: 16px;
  width: 250px;
  height: 45px;
}

.margin-left-div {
  margin-left: 0px
}

@media screen and (min-width: 380px) and (max-width: 410px) {

  .margin-left-div {
    margin-left: -20px
  }

}


@media screen and (min-width: 340px) and (max-width: 380px) {

  .margin-left-div {
    margin-left: -35px
  }

}

@media screen and (max-width: 350px) {

  .margin-left-div {
    margin-left: -50px
  }

}

.create_audio_form .MuiGrid-spacing-xs-4 {
  margin: 0px;
}

@media screen and (max-width: 400px) {
  .flex-direction-mobile {
    flex-direction: column;
  }

  .create_audio_form .MuiGrid-spacing-xs-4>.MuiGrid-item {
    padding: 0px;
  }
}


.create_audio_form .ant-upload.ant-upload-select-picture-card {
  border: 4px solid #1f1f1f;
  border-radius: 15px;
  width: 135px;
  height: 135px;
}

.outlined-upload {
  font-size: 32px;
  color: white;
  font-weight: 900;
  -webkit-text-stroke: 1px;
  -webkit-text-fill-color: transparent;
}

.create_audio_form .public-private-buttons {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 232px;
  height: 45px;
  flex-grow: 0;
  border-radius: 32px;
  border: solid 3px #212121;
  background-color: #070707;
}

.create_audio_form .button1_private {
  color: white;
  border-color: transparent;
  background-color: rgb(44, 43, 43);
  align-items: flex-start;
  border-radius: 25px;
  margin-right: 5px;
  width: 50%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.create_audio_form .button2_public {
  color: white;
  border-color: transparent;
  background-color: rgb(44, 43, 43);
  align-items: flex-start;
  border-radius: 25px;
  width: 50%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.audio-image-width {
  width: 48px;
}

.video-image-width {
  width: 96px;
}

.audio-edit-icon {
  position: absolute;
  top: 40%;
  left: -20px;
  width: 35px;
  height: 35px;
}

.video-edit-icon {
  position: absolute;
  top: 25%;
  left: -25px;
  width: 46px;
  height: 46px;
}


/* Video Form Css */

.create_video_form .ant-input {
  background-color: #020202 !important;
  border-color: #020202 !important;
  border-bottom: 4px solid #242528 !important;
  color: white !important;
  padding: 4px 0px !important;
}

.create_video_form .ant-input:focus,
.ant-input-focused {
  box-shadow: none !important;
}

.create_video_form .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.create_video_form .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.create_video_form .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding-bottom: 0px !important;
}

.create_video_form .ant-form-item-explain-error {
  text-align: start !important;
  margin-top: 0px !important;
}

.create_video_form .ant-form-item-explain,
.ant-form-item-extra {
  color: white !important;
  text-align: end !important;
  margin-top: 0px !important;
  cursor: pointer !important;
  align-self: start !important;
}

.create_video_form .ant-input-affix-wrapper {
  background-color: #17181c !important;
  border-color: #17181c !important;
  border-bottom: 4px solid #242528 !important;
  padding: 0px !important;
}

.create_video_form .ant-input-affix-wrapper>input.ant-input {
  border-bottom: none !important;
}

.create_video_form .ant-form-item-label>label {
  color: #8f92a1 !important;
}


.create_video_form .red_custom_btn_create {
  background-color: #fe0000 !important;
  border-color: #fe0000 !important;
  color: white !important;
  width: 232px;
  height: 58px;
  border-radius: 60px;
  font-size: 18px;
}

.create_video_form .MuiGrid-spacing-xs-4 {
  margin: 0px;
}

@media screen and (max-width: 400px) {
  .flex-direction-mobile {
    flex-direction: column;
  }

  .create_video_form .MuiGrid-spacing-xs-4>.MuiGrid-item {
    padding: 0px;
  }
}

.create_video_form .ant-upload.ant-upload-select-picture-card {
  border: 4px solid #1f1f1f;
  border-radius: 15px;
  width: 173px;
  height: 100px;
}


.create_video_form .public-private-buttons {
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 232px;
  height: 45px;
  flex-grow: 0;
  border-radius: 32px;
  border: solid 3px #212121;
  background-color: #070707;
}

.create_video_form .button1_private {
  color: white;
  border-color: transparent;
  background-color: rgb(44, 43, 43);
  align-items: flex-start;
  border-radius: 25px;
  margin-right: 5px;
  width: 50%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.create_video_form .button2_public {
  color: white;
  border-color: transparent;
  background-color: rgb(44, 43, 43);
  align-items: flex-start;
  border-radius: 25px;
  width: 50%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.playlist_name_width {
  width: 200px
}

@media screen and (max-width: 460px) {
  .playlist_name_width {
    width: 130px
  }
}

@media screen and (max-width: 370px) {
  .playlist_name_width {
    width: 110px
  }
}

@media screen and (min-width: 600px) {
  .height_width_menu {
    height: 60px !important;
    width: 200px !important;
  }
}