:root {
  --fontFamily: Inter;
  --karlafontFamily: karla;
}

.downloadMain {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgb(31, 31, 31);
}

.downloadLayout {
  background-color: rgb(31, 31, 31);
  height: 100vh;
}

.download_content {
  margin: 0px;
  background-color: black;
  height: 100vh !important;
}

.download_Contain {
  height: 90vh;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgb(9, 9, 9);
}

.audioplayer_Contain {
  height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgb(9, 9, 9);
}

.download-sub-height {
  height: 100% !important;
}

.download_lodder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.no-downloads {
  color: white;
  font-size: 20px;
  font-family: var(--karlafontFamily) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-transform: capitalize;
  font-weight: 900;
}

.download_songs_lists {
  margin: 0px 20px;
  height: 60% !important;
}

.downloadTable {
  height: 95vh;
  margin: 0px 15px;
  border-radius: 30px !important;
  background-color: #2c2c2c !important;
}

.downloadHead {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  gap: 5px;
  padding-top: 10px;
  margin: 0px 25px;
}

.downloadHead h3:nth-child(1) {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.downloadHead h3 {
  color: #ffffff;
  font-weight: bold;
  font-family: var(--fontFamily);
  text-align: center;
}

.month_track_contain {
  height: 85% !important;
  overflow: hidden auto;
}

.month_track_no-contain {
  height: 42vh !important;
  overflow: hidden auto;
}

.allTrack {
  height: 45%;
  margin: 0px 2px;
  margin-bottom: 5px;
  padding: 2px;
}

.trackInfo {
  overflow-y: auto;
  height: 78%;
  overflow-x: hidden;
}

/* download scroll */
.month_track_contain::-webkit-scrollbar-track {
  background-color: #141414;
  border-radius: 10px;
  border: solid 1px #222;
}

/* Handle */
.month_track_contain::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

/* Handle on hover */
.month_track_contain::-webkit-scrollbar-thumb:hover {
  background: red;
}



@media only screen and (max-width: 1200px) {
  .downloadHead {
    grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 1100px) {
  .downloadHead {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

}



@media only screen and (max-width: 610px) {
  .downloadHead {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .downloadHead h3:nth-child(3),
  .downloadHead h3:nth-child(4),
  .downloadHead h3:nth-child(5),
  .downloadHead h3:nth-child(6) {
    display: none;
  }

  .downloadBtn {
    display: none;
  }


}

.ant-dropdown-menu-title-content {
  flex: auto;
}

.ant-dropdown-menu-submenu-popup ul {
  background-color: #232323 !important;
}



ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  background-color: #000 !important;
  font-weight: 700;
  color: #fff !important;
  border: 3px solid #212121 !important;
  border-top: none !important;
  border-radius: 0 0 18px 18px;
}

span.ant-dropdown-menu-title-content {
  cursor: pointer;
  transition: all .3s;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -1px;
  font-family: var(--karlafontFamily) !important;
  font-size: 14px !important;
  color: white !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background-color: black !important;
}