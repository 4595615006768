:root {
  --fontFamily: Inter !important;
}

.dialog-Paper-link {
  background-color: #020202 !important;
  border-radius: 40px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
  border: solid 5px #1f1f1f !important;
  width: 373px !important;
  height: 260px;
  justify-content: center;
}

.dialogplaylistbox {
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  align-items: center
}

.dialogplaylistinsodebox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 275px
}

.dialogling {
  font-size: 42px;
  font-weight: 900;
  color: white;
  font-family: var(--fontFamily) !important
}

.linkedpcopied {
  font-size: 42px;
  font-weight: 900;
  color: white;
  font-family: var(--fontFamily) !important;
  margin-top: -20px
}

.checkbluelinkimage {
  width: 45px;
  height: 45px
}