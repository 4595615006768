/******************/
:root {
  --fontFamily: Inter !important;
  --fontFamilykarla: karla !important;
  --fontFamilyOpen: "Open Sans", sans-serif !important;
 }
 
 
 .user_comment_msg {
  /* width: 282px;
    height: 80px; */
  /* margin: 10px; */
  padding: 10px !important;
  border-radius: 15px;
  /* background-color: #171717; */
 }
 .icon-up-videos {
  width: 9px !important;
  height: 7px !important;
  margin-top: 7px !important;
 }
 
 
 .comment_username {
  font-family: var(--fontFamily) !important;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
 }
 
 
 .music-icons{
  width:22px;
  height: 22px;
 }
 .comment_time_font {
  width: 60px;
  height: 15px;
  border-radius: 16px;
  background-color: #070707;
  font-family:var(--fontFamily) !important;
  font-size: 10px;
  font-weight: bold;
  color: #717171;
  text-align: center;
 }
 
 
 .comment_msg {
  font-family: var(--fontFamily) !important;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  word-break: break-word;
  margin-top: 15px;
 }
 
 
 .plays-carasouel{
  font-size: 12px !important;
 font-family: var(--fontFamily) !important;
    font-weight: 600 !important;
 }
 
 
 .topsongs-cover-listing{
  border-radius: 10px;
    height: 48px;
    width: 48px;
 }
 
 
 /*******************/
 
 
 /* VideoList */
 .video-top-grid {
  display: inline-block !important;
  position: relative !important;
 }
 
 
 .songs-plays {
  margin-top: -5px !important;
  margin-left: -2px !important;
  font-family: var(--fontFamilyOpen) !important;
  font-size: 12px !important;
 }
 .userName_width {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
 }
 .pickgradient::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.21), #000);
  /* opacity: 0.6; */
  border-radius: 12px;
 
 
 }


 
 
 .pickgradient {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.21), #000);
  /* opacity: 0.6; */
  border-radius: 12px;
 }
 .vide-play-refresh {
  height: 21px;
  width: 32px;
  border-radius: 1px;
  margin-top: 3px;
 }
 .song-play-absolute {
  position: absolute !important;
  top: 41% !important;
  left: 40% !important;
  width: 43px !important;
  height: 43px !important;
 }
 
 
 .songs-left {
  position: absolute !important;
  top: 76% !important;
  left: 5% !important;
  width: 100% !important;
 }
 
 
 .songs-called {
  font-size: 14px !important;
  font-weight: bold !important;
    margin-bottom: 1em !important;
 }
 
 
 .videoList-desc {
  margin-top: -10px !important;
  font-size: 14px !important;
  color: #8f92a1 !important;
  font-family: var(--fontFamilykarla) !important;
 font-weight: normal !important;
 }
 
 
 .videoList-flex {
  display: flex !important;
  margin-top: -10px !important;
 }
 
 
 .videoList-color {
  color: #3959f9 !important;
  font-family:var(--fontFamilyOpen) !important;
  font-weight: 600 !important;
 }
 
 
 .column {
  float: left;
  width: 100%;
  padding: 10px;
  height: 300px;
  margin: 0px 2px;
  /* Should be removed. Only for demonstration */
 }
 
 
 .video-column {
  float: left;
  width: 100%;
  padding: 10px;
  height: 300px;
  margin: 0px 3px !important;
  /* Should be removed. Only for demonstration */
 }
 
 
 /* Clear floats after the columns */
 .row:after {
  content: "";
  display: table;
  clear: both;
 }
 
 
 .card-name {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #fff;
  width: 90%;
  overflow: hidden;
  /* display: inline-block; */
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--fontFamily) !important;
 }
 
 
 .recommend-parent-render {
  position: relative !important;
  margin-top: 1rem !important;
 }
 
 
 .recommend-display {
  display: flex !important;
  margin: 0px !important;
 }
 
 
 .recommend-height {
  height: 230px !important;
 }
 
 
 .recommend-text {
  color: #9f9f9f !important;
  font-size: 12px !important;
 }
 
 
 .activity-grid-container {
  border-radius: 5px !important;
  display: inline-block !important;
  position: relative !important;
 }
 
 
 .activity-play-song {
  position: absolute !important;
  top: 41% !important;
  left: 40% !important;
  width: 43px !important;
  height: 43px !important;
 }
 .activity-space-between {
  display: flex !important;
  justify-content: space-between !important;
 }
 .mr-0 {
  margin: 0px !important;
 }
 .activity-avatar {
  width: 38px;
  height: 38px;
 }
 
 
 .activity-listItemText {
  font-size: 16px !important;
  font-weight: bold !important;
  color: white !important;
 }
 
 
 .activity-sub-artist {
  color: #8f92a1 !important;
  font-size: 14px !important;
 }
 
 
 .activity-time {
  color: #3959f9 !important;
  font-size: 12px !important;
  font-weight: bold !important;
 }
 .activity-profile-img {
  object-fit: cover !important;
  width: 91% !important;
  height: 91% !important;
  margin: 10px !important;
  border-radius: 5px !important;
 }
 .activity-menuBtn {
  height: 18px !important;
  width: 18px !important;
  margin-top: 17px !important;
 }
 .activity-lShape {
  height: 40px !important;
  width: 28px !important;
  margin-left: 14px !important;
 }
 
 
 .activity-mt15 {
  margin-top: 15px !important;
 }
 
 
 .activity-nxzlogo {
  width: 30px !important;
  height: 18px !important;
  padding-left: 4px !important;
 }
 
 
 .activity-desc {
  font-size: 14px !important;
  color: #ffffff !important;
 }
 .activity-link {
  color: #2d47c7 !important;
  margin-top: 6px !important;
  font-size: 14px !important;
  margin-bottom: 10px !important;
 }
 
 
 .activity-display-parent {
  padding-right: 10px !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
 }
 
 
 .activity-dialog-profile {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
 }
 
 
 .activity-dialog-link {
  display: flex;
  justify-content: center !important;
  padding-top: 2px !important;
  cursor: pointer;
 }
 
 
 .activity-dialog-reshare {
  display: flex !important;
  justify-content: center !important;
  cursor: pointer;
 }
 
 
 .activity-msg-shape {
  display: flex !important;
  justify-content: center !important;
  padding-top: 6px !important;
  cursor: pointer;
 }
 .desc {
  /* font-size: 12px !important; */
  color: #8f92a1 !important;
 }
 
 
 .card-parentDiv {
  width: 148px !important;
  /* height: 148px !important; */
  border-radius: 12px !important;
 }
 
 
 /* .artist-name {
  margin-left: 20px !important;
 } */
 
 
 .playlist-parent {
  /* margin-left: 16px !important; */
  margin-left: -3px !important;
 }
 
 
 .flex {
  display: flex !important;
 }
 
 
 .dialog-title {
  padding: 10px 20px !important;
 }
 
 
 .play-head {
  font-size: 16px !important;
  font-weight: 800 !important;
  color: #fff !important;
 }
 .dialog-parent-list {
  max-height: 450px !important;
  overflow-y: scroll !important;
 }
 
 
 .dialog-list {
  padding: 10px 20px !important;
 }
 
 
 .dialog-list-item {
  border-radius: 10px !important;
  border: solid 1px rgba(151, 151, 151, 0.18) !important;
  margin: 10px 0px !important;
 }
 
 
 .relative {
  position: relative !important;
 }
 
 
 .dialog-avatar {
  /* width: 61px !important; */
  width: 100%;
  height: 61px !important;
 }
 
 
 .dialog-img {
  position: absolute !important;
  bottom: 3% !important;
  left: 65% !important;
 }
 
 
 .dialog-list-text {
  color: white !important;
  font-weight: bold !important;
  font-size: 16px !important;
  margin-left: 10px !important;
 }
 
 
 .dialog-text-span {
  color: #8f92a1 !important;
  font-size: 14px !important;
 }
 
 
 .dialog-list-btn {
  background-color: #3959f9 !important;
  border-radius: 13.5px !important;
 }
 .dialog-Unfollowlist-btn {
  background-color: #21232d !important;
  border-radius: 13.5px !important;
 }
 .dialog-load-more {
  color: #5d6c76 !important;
  font-size: 12px !important;
  text-align: center !important;
  margin-bottom: 5px !important;
 }
 
 
 .recommend-parent-div {
  border: solid 1px #1f1f1f !important;
  height: 277px !important;
  border-radius: 20px !important;
  padding: 20px !important;
 }
 
 
 .recommend-subparent {
  height: 316px !important;
 }
 
 
 .space-between {
  display: flex !important;
  justify-content: space-between !important;
 }
 
 
 .recommend-video {
  color: white !important;
  font-weight: 600 !important;
  font-size: 18px !important;
 }
 
 
 .see-all {
  color: white !important;
  font-weight: 500 !important;
  font-size: 14px !important;
 }
 
 
 .slider-parent-div {
  position: relative !important;
  margin-top: 1rem !important;
 }
 
 
 .mr0 {
  margin: 0px !important;
 }
 .img-grig {
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .slider-img {
  width: 310px !important;
  height: 155px !important;
  border-radius: 12px !important;
 }
  
 .card-sub-name {
  color: #dacfcf !important;
  font-size: 12px !important;
 }
 
 
 .second-grid {
  margin-top: -67% !important;
 }
 .second-grid-parent {
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
  border: solid 1px #1f1f1f !important;
  width: 100% !important;
  height: 526px !important;
 }
 
 
 .second-grid-pd {
  padding: 10px 20px !important;
 }
 
 
 .playlist-text {
  color: white !important;
  font-weight: 800 !important;
  font-size: 16px !important;
 }
 
 
 .recommend-parent-list {
  max-height: 450px !important;
  overflow-y: scroll !important;
 }
 
 
 .video-img-slider {
  width: 320px;
  height: 200px;
  border-radius: 12px !important;
 }
 
 
 .video-card-slider {
  width: 320px;
  height: 200px;
 }
 
 
 .recommend-slide-img {
  width: -webkit-fill-available;
  height: 175px;
 }
 
 
 .recommend-Button {
  background-color: #3959f9 !important;
  color: white !important;
  font-size: 10px !important;
  float: right !important;
  border-radius: 12px !important;
 }
 .activity-playlist-grid {
  margin-bottom: 10px;
  background-color: #171717;
  border-radius: 15px;
 }
 
 
 .recommend-sub-parent {
  border: solid 1px #1f1f1f;
  height: 297px;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 14px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
 }
 
 
 .activity-artist {
  font-size: 22px;
  color: white;
  margin-bottom: 0px;
 }
 .activity-artist-grid {
  position: absolute;
  top: 72%;
  left: 5%;
  width: 100%;
 }
 .song-desc {
  color: #8f92a1;
  font-size: 14px;
 }
 .recommend-heading {
  color: white;
  font-weight: 600;
  font-size: 18px;
 }

 .profile-text {
  color: #8f92a1 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  justify-content: center !important;
  display: flex !important;
  cursor: pointer;
 }
 .dialog-Paper {
  background-color: #121212 !important;
  border-radius: 20px !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
  border: solid 1px #1f1f1f !important;
  width: 618px !important;
 }
 .send-dialog-Paper {
  height: 413px !important;
 }
 .send-div {
  display: flex !important;
  padding: 32px !important;
  flex-direction: row !important;
 }
 .share-qr-text {
  color: #fff !important;
  font-weight: bold !important;
  font-size: 22px !important;
 }
 
 
 .share-parent-grid {
  display: flex !important;
 }
 
 
 .send-message-to {
  color: #8f92a1;
  font-size: 12px !important;
  font-family: var(--fontFamilykarla) !important;
}

.send-message-input {
  outline: 0px;
  border-width: 0px 0px 1px;
    border-color: #8f92a1;
  background-color: black;
  padding: 10px 10px 10px 30px;
  width: 100%;
  
  /* background-color: rgba(0, 0, 0, 0.15); */
}

.message-dialog{
  background-color: transparent !important;
  color:white !important

}
.send-message-input::-webkit-input-placeholder{
  font-family: var(--fontFamilykarla) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color:white !important
}
.add
 .add-comment {
  outline: 0px;
  border-width: 0px 0px 5px 0px;
  border-color: #171717;
  background-color: black;
  padding: 15px;
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.15); */
  /* margin-left: 40px; */
  border-radius: 5px;
  /* border-bottom-left-radius: 5px;*/
 }
 .send-message-textarea {
  outline: 0px;
  border-width: 0px 0px 1px;
  border-color: #8f92a1;
  width: 100%;
  background-color: transparent;

  /* background-color: rgba(0, 0, 0, 0.15); */
}

.send-message-textarea::-webkit-input-placeholder {
  font-family: var(--fontFamilykarla) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color:white !important

}

 .send-message-img {
  border-radius: 20px;
  position: absolute;
  top: 50%;
  /* left: 4px; */
  transform: translateY(-50%);
 }
 
 
 .commentBlock {
  /* height: 420px; */
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
 }
 
 
 .commenterName {
  margin: 0;
  text-align: left;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  color: #8f92a1;
  line-height: 2;
 }
 
 
 @media (max-width: 768px) {
  .commenterName {
    margin: 0;
    font-size: 11px;
  }
 }
 
 
 .controls[data-state="hidden"] {
  display: none;
 }
 
 
 .controls[data-state="visible"] {
  display: block;
 }
 .controls > * {
  float: left;
  width: 3.90625%;
  height: 100%;
  margin-left: 0.1953125%;
  display: block;
 }
 
 
 .controls > *:first-child {
  margin-left: 0;
 }
 
 
 ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: grey;
  font-weight: bold;
 }
 .send-message-box {
  position: relative;
  padding: 10px 10px 10px 0px;
 }
 
 
 @media (max-width: 600px) {
  .share-qr-text {
    font-size: 16px !important;
  }
  .qrCode-img {
    width: 115px;
    height: 105px;
  }
  .dialog-Paper {
    background-color: #121212 !important;
    border-radius: 20px !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5) !important;
    border: solid 1px #1f1f1f !important;
    width: 618px !important;
  }
  .dialog-list-text {
    margin-left: 1px !important;
    font-size: 14px !important;
  }
 
 
  .dialog-avatar {
    width: 57px !important;
    height: 57px !important;
  }
  .recommend-heading {
    font-size: 16px;
    text-align: center;
  }
  .see-all {
    font-size: 12px;
    text-align: center;
  }
  .video-img-slider {
    width: 220px;
    height: 180px;
    border-radius: 12px !important;
  }
 
 
  .song-desc {
    padding: 0px 13px !important;
    display: flex !important;
  }
 
 
  .xs-button {
    display: flex;
    /* justify-content: end; */
    justify-content: flex-end !important;
  }
 
 
  .recommend-sub-parent {
    margin-bottom: 10px;
    height: 311px;
  }
  .video-card-slider {
    width: 220px;
    height: 180px;
  }
  .activity-playlist-grid {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .activity-artist {
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .activity-artist-grid {
    top: 79% !important;
  }
  .video-card {
    /* width: 175px; */
    /* height: 175px; */
  }
 }
 @media (max-width: 1000px) {
  .recommend-slide-img {
    height: 157px;
  }
 }
 /* @media (max-width: 576px) { */
 @media (max-width: 600px) {

  .display-play_dark_back {
    /* display: flex;  */
    border: solid 1px rgba(151, 151, 151, 0.18);
    margin: auto !important;
    border-radius: 20px;
    padding: 3px;
    background: black;
    padding-top: 0px !important;
  }
  .allplaylist_listing{
 /* height: 56vh !important; */
 max-height: 64% !important;
 
 
  }
 
 
  .height-playlist_listing{
    height: 48vh !important;
      }
      .track_listing .trackList_div{
        /* height:23vh !important */
      }
 }
 
 
 @media (max-width: 590px) {
  .video-card-center {
    display: flex;
    justify-content: center;
  }
 }
 @media (max-width: 600px) {
  .borderRight {
    border-bottom: solid 1px #979797 !important;
    margin-bottom: 2px !important;
  }
  .display-play-grid {
    /* display: flex; */
    border: solid 1px rgba(151, 151, 151, 0.18);
    margin: auto !important;
    border-radius: 20px;
    padding: 3px;
    background: black;
  }
 
 
  .send-div {
    flex-direction: column !important;
  }
  .qrCode-img {
    width: 98px;
    height: 80px;
    cursor: pointer;
  }
  .img-grig {
    margin-bottom: 11px !important;
  }
  .profile-text {
    font-size: 12px !important;
  }
 }
 
 
 @media (max-width: 960px) {
  .activity-playlist-grid {
    display: flex !important;
    flex-direction: column !important;
  }
 }
 @media (max-width: 1280px) and (min-width: 960px) {
  .recommend-Button {
    float: none !important;
    width: 100% !important;
    /* background-color: #3959f9;
    color: white;
    font-size: 10px;
    border-radius: 12px; */
  }
 }
 
 
 @media (min-width: 600px) {
  .mt15 {
    margin-top: -15px !important;
  }
  .share-profile-grid {
    display: flex;
    /* justify-content: end; */
    justify-content: flex-end !important;
    flex-direction: column;
  }
  .borderRight {
    border-right: solid 1px #979797 !important;
  }
  .img-grig {
    align-items: flex-start !important;
  }
  .share-parent-grid {
    padding-left: 11px !important;
  }

 }
 @media (max-width: 600px) {
  .activity-profile-img {
    width: 96% !important;
  }
 }
 
 
 @media (max-width: 750px) {
  .add-comment {
    width: 94%;
  }
 }
 
 
 @media (max-width: 500px) {
  .add-comment {
    width: 90%;
  }
 }
 
 
 @media (max-width: 425px) {
  .add-comment {
    width: 86%;
  }
 }
 
 
 @media (max-width: 400px) {
  .add-comment {
    width: 90%;
  }
  .sendIcon_in_addComment {
    right: -5px !important;
  }
  .send-message-box {
    margin-left: -8px;
  }
  #add-comment_in_MsgSectionComponent::placeholder {
    font-size: 8px;
  }
  #send-message-box__in_addComment {
    margin-left: -16px;
  }
  #add-comment_in_MsgSectionComponent {
    width: 80%;
  }
  #sendIcon__MsgSectionComponent {
    right: 15px !important;
  }
 }
 
 
 /* @media (max-width: 380px){
  .add-comment {
    width: 82%;
  }
 }
 
 
 @media (max-width: 338px){
  .add-comment {
    width: 77%;
  }
 } */
 
 
 @media (max-width: 600px) {
  .videoList-desc {
    margin-top: -18px !important;
    font-size: 14px !important;
    color: #8f92a1 !important;
  }
  .top-grid-video {
    margin-top: -21px !important;
  }
 }

 .ad-placement-text-profile{
  font-family: var(--fontFamily) !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #FFCB37 !important;

}

.ad-placement-text-font{
  font-weight: 700 !important;
  color: #FFCB37 !important;
  display: inline-block;
  white-space: nowrap;
}