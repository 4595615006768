:root {
  --fontFamily: Karla !important;
  --fontFamily1: Inter !important
}

.dashboardWrapper {
  height: 100vh;
  overflow-y: scroll;
  background-color: black !important;
  overflow-x: hidden;
}

.streamRepostWrapper {
  background-color: black;
  height: 100vh;
  overflow: hidden;
}

.dashboardWrapper::-webkit-scrollbar-thumb {
  background-color: red !important;
}

.dashboardWrapper::-webkit-scrollbar-track {
  background: #585858 !important;
  border-radius: 10px !important
}

.dashboard_custom_tab .ant-tabs-tab-btn:focus {
  color: currentColor !important
}

.dashboardWrapper::-webkit-scrollbar {
  width: 10px;
  height: 0px !important;
}

.streamRepostscroll::-webkit-scrollbar-thumb {
  background-color: red !important;
}

.streamRepostscroll::-webkit-scrollbar-track {
  background: #585858 !important;
  border-radius: 10px !important
}

.streamRepostscroll::-webkit-scrollbar {
  width: 10px;
  height: 0px !important;
}

/* .displayad::-webkit-scrollbar-thumb {
  background-color: red !important;
}

.displayad::-webkit-scrollbar-track {
  background: #585858 !important;
  border-radius: 10px !important
}

.displayad::-webkit-scrollbar {
  width: 10px;
  height: 0px !important;
} */

.dashboard_custom_tab .ant-tabs-tab {
  font-size: 12px !important;
  font-weight: 800 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.67 !important;
  letter-spacing: 1px !important;
  text-align: center !important;
  color: #818181 !important;
  font-family: var(--fontFamily) !important;
}

.dashboard_custom_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: var(--fontFamily) !important;
  font-size: 16px !important;
  font-weight: 800 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.25 !important;
  letter-spacing: 1px !important;
  text-align: center !important;
  color: #ffffff !important;
}


.slideradd .MuiSlider-track {
  height: 10px !important;
  background: #3959F9 !important;
  border-radius: 15px !important
}

.slideradd .MuiSlider-rail {
  height: 15px !important;
  background: #141414 !important;
  border-radius: 15px !important
}

.slideradd .MuiSlider-valueLabel {
  width: 100px;
  height: 45px;
  border-radius: 30px;
  border: 3px solid #3959F9;
  top: -12px;
  background: #070707 !important;
  transform: unset !important;
  position: absolute !important;
}

.slideradd .MuiSlider-valueLabel>span {
  margin-left: 29px !important;
  margin-top: 4px !important;
  background: none !important;

}

.slideradd .PrivateValueLabel-label-148 {
  transform: unset !important;
}

.dashboard_custom_tab>.ant-tabs-nav .ant-tabs-nav-list {
  gap: 50px !important;
}

.dashboard_custom_tab .ant-tabs-ink-bar {
  border-bottom: 4px solid red !important;
}

.Streamrepost_custom_tab .ant-tabs-ink-bar {
  border-bottom: 4px solid red !important;
}

.Streamrepost_custom_tab .ant-tabs-tab-btn:focus {
  color: currentColor !important
}

.Streamrepost_custom_tab .ant-tabs-nav::before {
  border: none !important;
}

.Streamrepost_custom_tab .ant-tabs-nav {
  border-bottom: none !important;
}

.Streamrepost_custom_tab .ant-tabs-tab {
  font-family: var(--fontFamily1) !important;
  font-size: 22px !important;
  font-weight: 900 !important;
  line-height: 20px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
  color: #818181 !important;

}

.Streamrepost_custom_tab {
  width: 100% !important;
}

.Streamrepost_custom_tab>.ant-tabs-nav .ant-tabs-nav-list {
  gap: 20px !important;
  border-bottom: transparent !important;
}

.Streamrepost_custom_tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: var(--fontFamily) !important;
  font-size: 32px !important;
  font-weight: 900 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 20px !important;
  letter-spacing: 1px !important;
  text-align: center !important;
  color: #ffffff !important;
}




.reposthistorimage {
  width: 200px;
  height: 270px;
  gap: 0px;
  border: 5px solid #212121
}

@media screen and (max-width: 2500px) {
  .reposthistorimage {
    width: 280px !important;
  }
}

@media screen and (max-width: 2200px) {
  .reposthistorimage {
    width: 235px !important;
  }
}

@media screen and (min-width: 1900px) and (max-width: 1930px) {
  .reposthistorimage {
    width: 230px !important;
  }

}

@media screen and (min-width: 1850px) and (max-width: 1900px) {
  .reposthistorimage {
    width: 223px !important;
  }

}

@media screen and (min-width: 1800px) and (max-width: 1850px) {
  .reposthistorimage {
    width: 215px !important;
  }

}

@media screen and (min-width: 1750px) and (max-width: 1800px) {
  .reposthistorimage {
    width: 205px !important;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1750px) {
  .reposthistorimage {
    width: 195px !important
  }
}

@media screen and (min-width: 1650px) and (max-width: 1700px) {
  .reposthistorimage {
    width: 203px !important;
  }

  .pushchart {
    width: 420px !important
  }
}

@media screen and (min-width: 1600px) and (max-width: 1650px) {
  .reposthistorimage {
    width: 195px !important;
  }

  .pushchart {
    width: 408px !important
  }
}

@media screen and (min-width: 1550px) and (max-width: 1600px) {
  .reposthistorimage {
    width: 290px !important;
  }

  .totoadd {
    gap: 20px !important
  }

  .pushchart {
    width: 393px !important
  }
}

@media screen and (min-width: 1500px) and (max-width: 1550px) {
  .reposthistorimage {
    width: 280px !important;
  }

  .pushchart {
    width: 377px !important
  }

  .totoadd {
    gap: 20px !important
  }
}

@media screen and (min-width: 1450px) and (max-width: 1500px) {
  .reposthistorimage {
    width: 265px !important;
  }

  .totoadd {
    gap: 20px !important
  }

  .pushchart {
    width: 350px !important;
    height: 295px !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1450px) {
  .reposthistorimage {
    width: 260px !important;
  }

  .pushchart {
    width: 352px !important;
    height: 295px !important;
  }

  .totoadd {
    gap: 20px !important
  }
}

@media screen and (min-width: 1350px) and (max-width: 1400px) {
  .reposthistorimage {
    width: 250px !important;
  }

  .pushchart {
    width: 333px !important;
  }

  .totoadd {
    gap: 20px !important
  }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
  .reposthistorimage {
    width: 230px !important;
  }

  .pushchart {
    width: 315px !important;
  }

  .totoadd {
    gap: 15px !important
  }

  .donloadtext {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1300px) {
  .reposthistorimage {
    width: 220px !important;
  }

  .pushchart {
    width: 300px !important;
  }

  .totoadd {
    gap: 10px !important
  }

  .donloadtext {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .reposthistorimage {
    width: 210px !important;
  }

  .pushchart {
    width: 282px !important;
  }

  .totoadd {
    gap: 6px !important
  }

  .donloadtext {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1200px) {
  .reposthistorimage {
    width: 200px !important;

  }

  .dividerAdsetup {
    display: none !important;
  }
}

@media screen and (max-width: 1128px) {
  .reposthistorimage {
    width: 250px !important;
  }
}

@media screen and (max-width: 1073px) {
  .reposthistorimage {
    width: 220px !important;

  }

  .streamText {
    padding-left: 20px !important;
  }
}

@media screen and (max-width: 960px) {
  .reposthistorimage {
    width: 200px !important;

  }
}

@media screen and (max-width: 877px) {
  .reposthistorimage {
    width: 250px !important;

  }
}

@media screen and (max-width: 819px) {
  .reposthistorimage {
    width: 220px !important;

  }
}

@media screen and (max-width: 769px) {
  .reposthistorimage {
    width: 200px !important;

  }
}


@media screen and (min-width: 600px) and (max-width: 650px) {
  .reposthistorimage {
    width: 185px !important;
  }
}

@media screen and (max-width: 600px) {
  .mobileviewhide {
    display: none !important;

  }

  .repostimg {
    display: none !important;
  }

  .mobileshow {
    display: contents !important;
  }

  .streamRepostscroll {
    justify-content: normal !important;
    gap: 15px !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .displayad {
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 549px) {
  .reposthistorimage {
    width: 220px !important;

  }
}

@media screen and (max-width: 487px) {
  .reposthistorimage {
    width: 200px !important;

  }

  .pushchart {
    width: 350px !important;
  }
}

@media screen and (max-width: 435px) {
  .reposthistorimage {
    width: 180px !important;

  }

  .pushchart {
    width: 350px !important;
  }
}

@media screen and (max-width: 395px) {
  .reposthistorimage {
    width: 160px !important;
  }

  .streamText {
    padding-left: 20px !important;
  }
}

@media screen and (max-width: 380px) {

  .pushchart {
    width: 327px !important;
  }
}

@media screen and (max-width: 351px) {
  .reposthistorimage {
    width: 320px !important;

  }

  .pushchart {
    width: 300px !important;
  }
}

@media screen and (max-width: 351px) {
  .pushchart {
    width: 280px !important;
  }
}